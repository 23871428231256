.overview-section {
  height: 100vh;
  width: 100%;
}

.overview-zone {
  height: 75vh;
  width: 55%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.overview-h1 {
  font-family: 'Helvetica Neue';
  font-size: 85px;
  letter-spacing: -1px;
  margin: 0px;
  margin-bottom: 10px;

}

.overview-p {
  font-family: 'Inter';
}

.overview-button {
  margin-right: 10px;
}

.overview-button:disabled {
  background-color: #F5F5F5;
  color: #a5a5a5;
}
