.work-experience-section {
  height: 100vh;
  width: 100%;
}

.work-experience-zone {
  height: 80vh;
  width: 65%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.work-experience-h1 {
  font-family: 'Helvetica Neue';
  font-size: 85px;
  letter-spacing: -1px;
  margin: 0px;
  margin-bottom: 50px;
}

.work-experience-items {
  height: 100%;
  display: flex;
  gap: 10%;
}


.work-experience-choices {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.choice {
  background: #F5F5F5;
  border-radius: 50px;
  height: 50px;
  box-sizing: border-box;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  transition: background 0.2s ease;
}

.choice {
  cursor: pointer;
}

.choice:hover {
  background: #D9D9D9;
}

.choice.selected {
  color: white;
  fill: white;
  background: linear-gradient(90deg, #000000, #068F38, #EA5920, #FCEB3B)
}

.work-experience-arrow {
  margin-left: auto;
}

hr {
  border: 0.1px solid black;
  margin-left: 80px;
  margin-right: 80px;
}

.work-experience-data {
  width: 75%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.work-experience-data.active {
  opacity: 1;
}

.work-experience-header {
  display: flex;
  gap: 30px;
}

.company-logo {
  background-color: #000000;
  border-radius: 25px;
  width: 100px;
  height: 100px;
}

.work-experience-job-title {
  font-family: 'Helvetica Neue';
  font-size: 30px;
  letter-spacing: -1px;
  margin: 0px;
}

.work-experience-company {
  font-family: 'Helvetica Neue';
  font-size: 30px;
  letter-spacing: -1px;
  margin: 0px;
  font-weight: 300;
}

.work-experience-span {
  font-family: 'Helvetica Neue';
  font-size: 30px;
  letter-spacing: -1px;
  margin: 0px;
  font-weight: 300;
  font-style: italic;
}

.work-experience-description {
  transition: color 0.2s;
}

.work-experience-button {
  width: 25%;
}
