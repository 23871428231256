.education-item-zone {
  width: 400px;
  height: 150px;
  flex: 1 2 auto;

  display: flex;
  background-color: #F5F5F5;
  border-radius: 200px;
  box-sizing: border-box;
  position: relative;
}

.education-item-zone:hover {
  justify-content: center;
  align-items: center;
}

.education-item-primary-info,
.education-item-secondary-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s ease-in-out;
}

.education-item-primary-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-left: 40px;
  opacity: 1;
}

.education-item-secondary-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 75%;
  margin-left: 15%;
  opacity: 0;
}

.education-item-zone:hover .education-item-primary-info {
  opacity: 0;
}

.education-item-zone:hover .education-item-secondary-info {
  opacity: 1;
}

.education-item-primary-info-text {
  font-family: 'Inter';
  font-size: 17px;
}

.institution-logo {
  width: auto;
  height: 85px;
  border-radius: 100px;
}

.institution-logo .st0 { background: #000000; }
.institution-logo .st1 { background: #ffffff; }

.institution-name {
  margin: 4px;
}

.education-span {
  margin: 4px;
}

.programme-name {
  font-size: 17px;
  font-family: 'Inter Bold';
  padding: 0;
  margin-top: 0px;
  margin-bottom: 10px;
}

.education-details {
  font-size: 17px;
  margin: 0;
}
