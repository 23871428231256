.navigation-bar {
  font-family: 'DMMono';
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 4vh;
}

.navigation-bar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigation-bar a {
  color: black;
  text-decoration: none;
}

.navigation-bar li {
  margin-bottom: 3px;
  cursor: pointer;
}

.navigation-bar a:hover,
.navigation-bar a.active {
  font-weight: bold;
}

.unavailable-section {
  display: flex;
  align-items: center;
  gap: 7px;
}

.unavailable-section-title {
  color: rgb(159, 159, 159);
}

.coming-soon {
  font-size: 11px;
  font-family: 'Inter Bold';
  color: white;
  background: rgb(255, 83, 83);
  border-radius: 20px;
  padding: 3px;
  width:40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
