.skill-pill-text {
  border-radius: 30px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 10px;
  margin: 4px 0;
  font-family: 'Inter Bold';
  font-size: 11px;
  color: white;
}
