.skills-section {
  height: 100vh;
  width: 100%;
}

.skills-zone {
  height: 75vh;
  width: 60%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.skills-h1 {
  font-family: 'Helvetica Neue';
  margin: 0px;
  font-size: 85px;
  letter-spacing: -1px;
}

.skills-collection {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.skills-col-1 {
  width: 50%;
}

.skills-col-2 {
  width: 50%;
}

.skill-group-title {
  font-family: 'Helvetica Neue';
  font-size: 28px;
  letter-spacing: -1px;
  margin: 0px;
  margin-bottom: 10px;
}

.skill-group-body {
  font-family: 'Helvetica Neue';
  font-size: 28px;
  letter-spacing: -1px;
  margin: 0px;
  font-weight: 300;
  margin-bottom: 10px;
}
