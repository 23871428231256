@font-face {
  font-family: 'DMMono';
  src: url('../fonts/DMMono-Regular.ttf') format('truetype');
  font-style: normal;
}

.welcome-page {
  display: flex;
  height: 100vh;
}

.welcome-page-left {
  padding: 4vh;
  display: flex;
  flex-direction: column;
  flex: 40%;
}

.welcome-page-right {
  display: flex;
  flex: 60%;
  position: relative;
}

.rope {
  position: absolute;
  height: auto;
  width: 90%;
  right: 0;
  bottom: 0;

}

.weird-forms {
  width: 100%;
  height: 100%;
}

.resume-header {
  font-family: 'DMMono'
}

.welcome-page-h1 {
  font-family: 'Helvetica Neue';
  font-size: 85px;
  margin: 0;
  margin-top: 0.5em;
  margin-bottom: 0em;
  line-height: 85px;
  letter-spacing: -1px;
}

.welcome-page-h2 {
  font-family: 'Helvetica Neue';
  font-weight: 300;
  font-size: 85px;
  margin: 0;
  margin-bottom: 1.5em;
  line-height: 85px;
  letter-spacing: -1px;
}
