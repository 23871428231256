.activities-section {
  height: 100vh;
  width: 100%;
}

.activities-zone {
  height: 75vh;
  width: 70%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: left;
  gap: 25px;
}

.activities-h1 {
  font-family: 'Helvetica Neue';
  margin: 0px;
  font-size: 85px;
  letter-spacing: -1px;
  margin-left: 5%;
}

.activities-carousel {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.arrow-zone {
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5%;
  border-radius: 100px;
  transition: background-color 0.2s ease;
}

.arrow-zone:hover {
  background-color: #F5F5F5;
}

.activity-item {
  background-color: #F5F5F5;
  border-radius: 60px;
  padding: 40px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 90%;
  align-self: stretch;
}

.activity-item-content {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.activity-item-content.active {
  opacity: 1;
}

img {
  height: 40px;
  width: 40px;
}

.activity-title {
  font-family: 'Helvetica Neue';
  font-size: 35px;
  letter-spacing: -1px;
  margin: 0px;
}

.activity-span {
  font-family: 'Helvetica Neue';
  font-size: 35px;
  letter-spacing: -1px;
  margin: 0px;
  font-weight: 300;
  font-style: italic;
}

.activity-description {
  width: 75%;
}

.activity-button {
  margin-right: 10px;
}

.activity-button:disabled {
  background-color: rgb(220, 220, 220);
  color: rgb(164, 164, 164);
}
