@font-face {
  font-family: 'Inter';
  src: url('fonts/Inter-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Inter Bold';
  src: url('fonts/Inter-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'DMMono';
  src: url('fonts/DMMono-Regular.ttf') format('truetype');
  font-style: normal;
}

button {
  height: 45px;
  width: 18%;
  margin-top: 15px;
  font-family: 'DMMono';
  border: none;
  background-color: #F5F5F5;
  text-align: center;
  transition: background-color 0.2s ease;
}

button:hover {
  color: white;
  background-color: black;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

section {
  width: 100%;
  height: 100vh;
  scroll-snap-align: start;
  /* background-color: white;
  box-shadow: 0 0 50px rgb(229, 229, 229); */
}

.disabled-window {
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  color: black;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'DMMono';
  font-size: 15px;
}

.disabled-message {
  margin-left: 5vh;
  margin-right: 5vh;
}
