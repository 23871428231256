.education-section {
  height: 100vh;
  width: 100%;
}

.education-zone {
  height: 75vh;
  width: 65%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.education-zone > *:not(:first-child):not(:last-child) {
  margin-bottom: 10px;
}

.education-h1 {
  font-family: 'Helvetica Neue';
  font-size: 85px;
  letter-spacing: -1px;
  margin: 0px;
  margin-bottom: 30px;
}

.education-items {
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex-wrap: wrap;
}
